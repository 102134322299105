import React from 'react'
import _ from 'lodash'

const CONVERTER = {
  DEFAULT: {
    toStorage: _.identity,
    fromStorage: _.identity,
  },
  BOOLEAN: {
    toStorage: (value) => {
      return value.toString()
    },
    fromStorage: (value) => {
      return value === 'true' ? true : value === 'false' ? false : undefined
    },
  },
}

const NONE_STORAGE = {
  getItem: _.noop,
  setItem: _.noop,
  removeItem: _.noop,
}

const getSafeOptions = (options) => {
  return _.isObject(options) ? options : { defaultValue: options }
}

const getItem = (storage, key, oldKey) => {
  let item = storage.getItem(key)
  if (!item && oldKey) {
    // Retrieve and remove item from old storage key
    item = storage.getItem(oldKey)
    storage.removeItem(oldKey)
  }
  return item
}

const useStateWithStorage = (storageType, storageKey, options) => {
  const storage = !storageKey ? NONE_STORAGE : storageType === 'session' ? sessionStorage : localStorage

  const { defaultValue, oldStorageKey } = getSafeOptions(options)
  const { toStorage, fromStorage } = _.isBoolean(defaultValue) ? CONVERTER.BOOLEAN : CONVERTER.DEFAULT

  const [value, setValue] = React.useState(() => fromStorage(getItem(storage, storageKey, oldStorageKey)))

  const handleValueChange = React.useCallback(
    (newValue) => {
      setValue(newValue)
      const storedValue = toStorage(newValue)
      if (_.isNil(storedValue)) {
        storage.removeItem(storageKey)
      } else {
        storage.setItem(storageKey, storedValue)
      }
    },
    [storage, storageKey, toStorage],
  )

  return [value ?? defaultValue, handleValueChange]
}

export default useStateWithStorage
