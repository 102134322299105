import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import EnvironmentContext from 'environment/EnvironmentContext'
import useAppTitle from 'environment/useAppTitle'
import AuthenticationHelper from '../AuthenticationHelper'
import FormSubmitWithRecaptcha from '../FormSubmitWithRecaptcha'

const ERROR_LABEL = {
  EMAIL: {
    blank: 'errorEmailBlank',
    invalid: 'errorEmailInvalid',
  },
}

const getErrorMessagesFromApiResponse = (resetResponse, t) => {
  const standard = AuthenticationHelper.getStandardErrorMessagesFromApiResponse(resetResponse)
  const email = _.get(resetResponse, 'email.0')
  let result = {
    email: _.get(ERROR_LABEL.EMAIL, email, null),
    recaptcha: standard.recaptcha,
    global: standard.global,
  }
  _.forEach(result, (value, key) => {
    if (!_.isNil(value)) {
      result[key] = t(value)
    }
  })
  return result
}

const ResetPasswordPage = (props) => {
  const { t } = useTranslation('authentication', { keyPrefix: 'ResetPasswordPage' })
  const environment = React.useContext(EnvironmentContext)
  const [email, setEmail] = React.useState('')
  const [resetErrorResponse, setResetErrorResponse] = React.useState(null)

  useAppTitle(t('documentTitle'))

  const baseUrl = environment.API_BASE_URL
  const errorMessages = React.useMemo(() => {
    return getErrorMessagesFromApiResponse(resetErrorResponse, t)
  }, [resetErrorResponse, t])

  const handleFormSubmit = (recaptcha) => {
    return AuthenticationHelper.passwordReset(baseUrl, email, recaptcha)
      .then(() => {
        props.onResetSuccess(email)
      })
      .catch(setResetErrorResponse)
  }

  React.useEffect(() => {
    setEmail(props.presetEmail)
  }, [props.presetEmail])

  return (
    <div className="reset-password-page">
      <Form.Group className="form-group">
        <Form.Label>{t('emailLabel')}</Form.Label>
        <Form.Control
          type="email"
          placeholder={t('emailPlaceHolder')}
          autoFocus
          autoComplete="email"
          maxLength="150"
          isInvalid={errorMessages.email !== null}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Form.Control.Feedback type="invalid">{errorMessages.email}</Form.Control.Feedback>
      </Form.Group>

      <FormSubmitWithRecaptcha
        submitLabel={t('submitLabel')}
        recaptchaError={errorMessages.recaptcha}
        onSubmit={handleFormSubmit}
      />

      <div className="link-container">
        <Link to="/signin" className="link">
          {t('returnToLogin')}
        </Link>
      </div>
    </div>
  )
}

ResetPasswordPage.propTypes = {
  presetEmail: PropTypes.string,
  onResetSuccess: PropTypes.func.isRequired,
}

export default ResetPasswordPage
