const ENVIRONMENT = {
  VERSION: process.env.REACT_APP_VERSION,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_TASK_URL: process.env.REACT_APP_API_TASK_URL,
  MAPTILER_KEY: process.env.REACT_APP_MAPTILER_KEY,
  GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
  GOOGLE_SEARCH_API_KEY: process.env.REACT_APP_GOOGLE_SEARCH_API_KEY,
  DISABLE_APP_REDIRECTION: process.env.REACT_APP_DISABLE_APP_REDIRECTION,
  MAINTENANCE: process.env.REACT_APP_MAINTENANCE,
  BETA: process.env.REACT_APP_BETA,
}

export default ENVIRONMENT
