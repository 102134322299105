import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'
import NoRouteMatch from './NoRouteMatch'

const ExactRoute = (props) => {
  return (
    <Routes>
      <Route index element={props.children} />
      <Route path="*" element={<NoRouteMatch />} />
    </Routes>
  )
}

ExactRoute.propTypes = {
  children: PropTypes.node,
}

export default ExactRoute
