import _ from 'lodash'
import Axios from 'axios'
import WebServiceHelper from 'generic/WebServiceHelper'

const URI = {
  LOGIN: 'rest-auth/login/',
  LOGOUT: 'rest-auth/logout/',
  PASSWORD_RESET: 'rest-auth/password/reset/',
  PASSWORD_RESET_CONFIRM: 'rest-auth/password/reset/confirm/',
}
const ERROR_LABEL = {
  DEFAULT: 'errorDefault',
  RECAPTCHA: {
    captcha_invalid: 'errorRecaptchaInvalid',
  },
}

class AuthenticationHelper {
  static getTokenFromCredentials(apiBaseUrl, email, password, recaptcha) {
    const method = 'post'
    const url = WebServiceHelper.joinUrl(apiBaseUrl, URI.LOGIN)
    const data = { email, password, recaptcha }
    return Axios.request({ method, url, data })
      .then(_.property('data.key'))
      .catch((error) => {
        return Promise.reject(_.get(error, 'response.data'))
      })
  }

  static logout(apiBaseUrl) {
    const method = 'post'
    const url = WebServiceHelper.joinUrl(apiBaseUrl, URI.LOGOUT)
    return Axios.request({ method, url }).catch((error) => {
      console.error(error)
    })
  }

  static passwordReset(apiBaseUrl, email, recaptcha) {
    const method = 'post'
    const url = WebServiceHelper.joinUrl(apiBaseUrl, URI.PASSWORD_RESET)
    const data = { email, recaptcha }
    return Axios.request({ method, url, data }).catch((error) => {
      return Promise.reject(_.get(error, 'response.data'))
    })
  }

  static passwordResetConfirm(apiBaseUrl, uid, token, newPassword1, newPassword2, recaptcha) {
    const method = 'post'
    const url = WebServiceHelper.joinUrl(apiBaseUrl, URI.PASSWORD_RESET_CONFIRM)
    const data = { uid, token, newPassword1, newPassword2, recaptcha }
    return Axios.request({ method, url, data }).catch((error) => {
      return Promise.reject(_.get(error, 'response.data'))
    })
  }

  static getStandardErrorMessagesFromApiResponse = (apiResponse) => {
    const recaptcha = _.get(apiResponse, 'recaptcha.0')
    return {
      recaptcha: _.get(ERROR_LABEL.RECAPTCHA, recaptcha, null),
      global: !_.isNil(apiResponse) && _.isEmpty(apiResponse) ? ERROR_LABEL.DEFAULT : null,
    }
  }
}

export default AuthenticationHelper
