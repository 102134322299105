import { lazy } from 'react'

const SIRAO_CONFIG = {
  name: 'Sirao',
  title: 'Sirao - Roseau Technologies',
  websiteUrl: 'https://sirao.fr',
  appUrl: process.env.REACT_APP_SIRAO_URL || 'https://app.sirao.fr',
  helpUrl: 'https://aide.sirao.fr',
  supportEmail: 'support@sirao.fr',
  faviconPath: '/favicon-sirao.ico',
  SvgLogo: lazy(() => import('./SiraoLogo')),
  SvgIcon: lazy(() => import('./SiraoIcon')),
  styles: {
    root: 'sirao',
    backgroundLogo: 'sirao-background-logo',
    backgroundIcon: 'sirao-background-icon',
  },
  isBeta: false,
}

export default SIRAO_CONFIG
