import React from 'react'
import PropTypes from 'prop-types'
import DropdownItem from 'react-bootstrap/DropdownItem'
import If from '../If'
import MaterialSymbol from '../MaterialSymbol'
import './RtDropdownItem.css'

const RtDropdownItem = React.forwardRef(
  ({ icon, iconSize, iconFill, variant, isDisabled: isDisabledProp, isActive, ...props }, ref) => {
    const isDisabled = isDisabledProp || props.disabled
    const variantClassName = variant && !isDisabled ? `text-${variant}` : ''
    const iconClassName = icon ? 'has-icon' : ''
    return (
      <DropdownItem
        {...props}
        ref={ref}
        className={`rt-dropdown-item ${props.className || ''} ${variantClassName} ${iconClassName}`}
        disabled={isDisabled}
        active={isActive || props.active}
      >
        {props.children}
        <If cond={!!icon}>
          <MaterialSymbol className="rt-dropdown-item-icon" icon={icon} size={iconSize ?? 20} fill={iconFill} />
        </If>
      </DropdownItem>
    )
  },
)

RtDropdownItem.propTypes = {
  ...DropdownItem.propTypes,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.node,
}

export default RtDropdownItem
