import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SuccessMessage from 'generic/RtSuccessMessage'
import useAppTitle from 'environment/useAppTitle'

const ResetSuccessPage = (props) => {
  const { t } = useTranslation('authentication', { keyPrefix: 'ResetSuccessPage' })

  useAppTitle(t('documentTitle'))

  return (
    <div className="reset-success-page">
      <SuccessMessage headingIconName="mail" title={t('successTitle')}>
        <p>
          <Trans t={t} i18nKey="successMessage1">
            Des instructions pour définir un nouveau mot de passe ont été envoyées à l'adresse{' '}
            <strong>{{ email: props.email }}</strong>.
          </Trans>
        </p>
        <p>{t('successMessage2')}</p>
        <p>{t('successMessage3')}</p>

        <div className="link-container">
          <Link to="/signin" className="link">
            {t('backToLoginLabel')}
          </Link>
        </div>
      </SuccessMessage>
    </div>
  )
}

ResetSuccessPage.propTypes = {
  email: PropTypes.string.isRequired,
}

export default ResetSuccessPage
