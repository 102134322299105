import { lazy } from 'react'

// TODO Change icon from Sirao to Berenis
const BERENIS_CONFIG = {
  name: 'Berenis',
  title: 'Berenis - Roseau Technologies',
  websiteUrl: 'https://berenis.app',
  appUrl: process.env.REACT_APP_BERENIS_URL || 'https://app.berenis.app',
  helpUrl: 'https://help.berenis.app',
  supportEmail: 'support@berenis.app',
  faviconPath: '/favicon-berenis.ico',
  SvgLogo: lazy(() => import('./BerenisLogo')),
  SvgIcon: lazy(() => import('./BerenisIcon')),
  styles: {
    root: 'berenis',
    backgroundLogo: 'berenis-background-logo',
    backgroundIcon: 'berenis-background-icon',
  },
  isBeta: true,
}

export default BERENIS_CONFIG
