import React from 'react'

const useControlledState = (controlledValue, onValueChange, initialValue) => {
  const [value, setValue] = React.useState(initialValue)

  const handleValueChange = React.useCallback(
    (newValue, ...other) => {
      setValue(newValue)
      onValueChange && onValueChange(newValue, ...other)
    },
    [onValueChange],
  )

  return [controlledValue !== undefined ? controlledValue : value, handleValueChange]
}

export default useControlledState
