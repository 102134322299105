import PropTypes from 'prop-types'

const If = (props) => (props.cond ? props.children : null)

If.propTypes = {
  cond: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default If
