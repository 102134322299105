import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const RtTooltip = (props) => {
  const [show, setShow] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false)

  const tooltipId = React.useMemo(() => _.uniqueId('tooltip-'), [])

  React.useEffect(() => {
    let cleanup
    if (show) {
      const timeoutId = setTimeout(() => setIsVisible(true), 5)
      cleanup = () => clearTimeout(timeoutId)
    } else {
      setIsVisible(false)
    }
    return cleanup
  }, [show])

  return props.message ? (
    <OverlayTrigger
      show={show && !props.isDisabled}
      placement={props.placement || 'auto'}
      trigger={props.trigger}
      delay={_.isNil(props.delay) ? 50 : props.delay}
      overlay={
        <Tooltip id={tooltipId} style={isVisible ? null : { visibility: 'hidden' }}>
          {props.message}
        </Tooltip>
      }
      onToggle={setShow}
    >
      {props.children}
    </OverlayTrigger>
  ) : (
    props.children
  )
}

RtTooltip.propTypes = {
  isDisabled: PropTypes.bool,
  message: PropTypes.node,
  placement: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      show: PropTypes.number,
      hide: PropTypes.number,
    }),
  ]),
  children: PropTypes.node.isRequired,
}

export default RtTooltip
