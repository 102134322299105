import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import useQueryString from 'generic/useQueryString'
import EnvironmentContext from 'environment/EnvironmentContext'
import useAppTitle from 'environment/useAppTitle'
import AuthenticationHelper from '../AuthenticationHelper'
import FormSubmitWithRecaptcha from '../FormSubmitWithRecaptcha'

const ERROR_LABEL = {
  LINK: 'invalidLinkError',
  PASSWORD: {
    blank: 'passwordBlankError',
  },
  PASSWORD_VALIDATION: {
    password_too_short: 'passwordTooShortError',
    password_too_common: 'passwordTooCommonError',
    password_entirely_numeric: 'passwordNumericError',
    password_too_similar: 'passwordTooSimilarError',
  },
  PASSWORD_CONFIRM: {
    blank: 'confirmPasswordBlankError',
    password_mismatch: 'passwordMismatchError',
  },
}

const getErrorMessagesFromApiResponse = (resetResponse) => {
  const standard = AuthenticationHelper.getStandardErrorMessagesFromApiResponse(resetResponse)
  const uid = _.get(resetResponse, 'uid.0')
  const token = _.get(resetResponse, 'token.0')
  const password = _.get(resetResponse, 'newPassword1.0')
  const passwordConfirm = _.get(resetResponse, 'newPassword2.0')
  const globalLink = uid || token ? ERROR_LABEL.LINK : null
  return {
    password: _.get(ERROR_LABEL.PASSWORD, password, null),
    passwordValidation: _.get(ERROR_LABEL.PASSWORD_VALIDATION, passwordConfirm, null),
    passwordConfirm: _.get(ERROR_LABEL.PASSWORD_CONFIRM, passwordConfirm, null),
    recaptcha: standard.recaptcha,
    global: globalLink || standard.global,
  }
}

const ResetConfirmPage = (props) => {
  const { t } = useTranslation(['authentication'], { keyPrefix: 'ResetConfirmPage' })
  const environment = React.useContext(EnvironmentContext)
  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [resetErrorResponse, setResetErrorResponse] = React.useState(null)

  useAppTitle(t('documentTitle'))

  const baseUrl = environment.API_BASE_URL
  const queryParams = useQueryString()
  const errorMessages = React.useMemo(() => {
    return getErrorMessagesFromApiResponse(resetErrorResponse)
  }, [resetErrorResponse])

  const handleFormSubmit = (recaptcha) => {
    return AuthenticationHelper.passwordResetConfirm(
      baseUrl,
      queryParams.uid,
      queryParams.token,
      password,
      passwordConfirm,
      recaptcha,
    )
      .then(props.onSuccess)
      .catch(setResetErrorResponse)
  }

  const passwordError = errorMessages.password || errorMessages.passwordValidation

  return (
    <div className="reset-confirm-page">
      <Form.Group className="form-group">
        <Form.Label>{t('passwordLabel')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('passwordPlaceholder')}
          autoComplete="new-password"
          isInvalid={passwordError !== null}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">{passwordError && t(passwordError)}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>{t('confirmPasswordLabel')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('passwordPlaceholder')}
          autoComplete="new-password"
          isInvalid={errorMessages.passwordConfirm !== null}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessages.passwordConfirm && t(errorMessages.passwordConfirm)}
        </Form.Control.Feedback>
      </Form.Group>

      <FormSubmitWithRecaptcha
        submitLabel={t('submitLabel')}
        recaptchaError={errorMessages.recaptcha}
        globalErrorMessage={errorMessages.global && t(errorMessages.global)}
        onSubmit={handleFormSubmit}
      />
    </div>
  )
}

ResetConfirmPage.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default ResetConfirmPage
