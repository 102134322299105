import React from 'react'
import PropTypes from 'prop-types'
import RtButton from '../RtButton'
import './RtBorderlessButton.css'

const RtBorderlessButton = React.forwardRef(({ isColoredOnHover, ...props }, ref) => {
  const className = props.className || ''
  const colorOnHoverClassName = isColoredOnHover ? 'color-on-hover' : ''

  return (
    <RtButton
      {...props}
      ref={ref}
      className={`rt-borderless-button ${className} ${colorOnHoverClassName}`}
      style={{ '--rt-borderless-button-color': `var(--bs-${props.variant})` }}
    />
  )
})

RtBorderlessButton.propTypes = {
  ...RtButton.propTypes,
  className: PropTypes.string,
  variant: PropTypes.string,
  isColoredOnHover: PropTypes.bool,
}

export default RtBorderlessButton
