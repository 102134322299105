import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import LocalisationHelper from '../LocalisationHelper'
import RtDropdown from '../RtDropdown'
import RtLanguageDropdownToggle from './RtLanguageDropdownToggle'
import './RtLanguageDropdown.css'

const LANGUAGES = {
  fr: 'Français',
  en: 'English',
}

const RtLanguageDropdown = (props) => {
  const { i18n } = useTranslation()

  const [language, setLanguage] = React.useState(LocalisationHelper.getLanguageCode(i18n.language))

  React.useEffect(() => {
    i18n.changeLanguage(language)
  }, [language, i18n])

  return (
    <div className="rt-language-dropdown">
      <RtDropdown align={props.align} onSelect={setLanguage}>
        <RtDropdown.Toggle as={RtLanguageDropdownToggle}>
          <div className="rt-language-dropdown-toggle-label">
            <span>{language.toUpperCase()}</span>
          </div>
        </RtDropdown.Toggle>
        <RtDropdown.Menu>
          {_.map(LANGUAGES, (name, code) => (
            <RtDropdown.Item
              key={code}
              as="button"
              className={`rt-language-dropdown-item ${code === language ? 'selected' : ''}`}
              eventKey={code}
            >
              {name} [{code.toUpperCase()}]
            </RtDropdown.Item>
          ))}
        </RtDropdown.Menu>
      </RtDropdown>
    </div>
  )
}

RtLanguageDropdown.propTypes = {
  align: PropTypes.string,
}

export default RtLanguageDropdown
