import { lazy } from 'react'

const GRID_CAPACITY_CONFIG = {
  name: 'Grid Capacity',
  title: 'Grid Capacity - Roseau Technologies',
  websiteUrl: 'https://grid-capacity.com',
  appUrl: process.env.REACT_APP_GRIDCAPACITY_URL || 'https://app.grid-capacity.com',
  helpUrl: 'https://aide.grid-capacity.com',
  supportEmail: 'support@grid-capacity.com',
  faviconPath: '/favicon-gridcapacity.ico',
  SvgLogo: lazy(() => import('./GridCapacityLogo')),
  SvgIcon: lazy(() => import('./GridCapacityIcon')),
  styles: {
    root: 'gridcapacity',
    backgroundLogo: 'gridcapacity-background-logo',
    backgroundIcon: 'gridcapacity-background-icon',
  },
  isBeta: false,
}

export default GRID_CAPACITY_CONFIG
