import _ from 'lodash'
import { arrow as arrowModifier } from '@popperjs/core'

const getPersistentModifiersData = (state, name, property) => {
  return _.get(state.persistentModifiersData, [name, property])
}

const setPersistentModifiersData = (state, name, property, value) => {
  if (!state.persistentModifiersData) {
    state.persistentModifiersData = {}
  }
  if (!state.persistentModifiersData[name]) {
    state.persistentModifiersData[name] = {}
  }
  state.persistentModifiersData[name][property] = value
}

const setWidthStyle = (state, property, isDefined) => {
  let shouldUpdate
  if (isDefined) {
    const currentValue = parseFloat(state.elements.popper.style[property])
    shouldUpdate = _.round(currentValue, 0) !== _.round(state.rects.reference.width, 0)
  } else {
    shouldUpdate = !!state.elements.popper.style[property]
  }

  if (shouldUpdate) {
    state.elements.popper.style[property] = isDefined ? `${state.rects.reference.width}px` : ''
  }

  return shouldUpdate
}

const updatePopperWidth = (state, name, instance, minWidth, maxWidth) => {
  // The reset attribute prevents unwanted loop due to the instance re-update
  const reset = getPersistentModifiersData(state, name, 'reset')
  const hasMinWidthUpdated = setWidthStyle(state, 'minWidth', minWidth)
  const hasMaxWidthUpdated = setWidthStyle(state, 'maxWidth', maxWidth)
  if (!reset && (hasMinWidthUpdated || hasMaxWidthUpdated)) {
    setPersistentModifiersData(state, name, 'reset', true)
    instance.update()
  } else {
    setPersistentModifiersData(state, name, 'reset', false)
  }
}

const POPPER_MODIFIER_CONFIG = {
  sameWidth: {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state, name, instance }) => {
      updatePopperWidth(state, name, instance, true, true)
    },
    effect: ({ state }) => {
      state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
      state.elements.popper.style.maxWidth = `${state.elements.reference.offsetWidth}px`
    },
  },
  minWidth: {
    name: 'minWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state, name, instance }) => {
      updatePopperWidth(state, name, instance, true, false)
    },
    effect: ({ state }) => {
      state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
    },
  },
  maxWidth: {
    name: 'maxWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state, name, instance }) => {
      updatePopperWidth(state, name, instance, false, true)
    },
    effect: ({ state }) => {
      state.elements.popper.style.maxWidth = `${state.elements.reference.offsetWidth}px`
    },
  },
  arrow: {
    ...arrowModifier,
    name: 'customArrow',
    fn: (args) => {
      arrowModifier.fn(args)
      const { state, name } = args
      const arrow = state.elements.arrow
      if (arrow) {
        const { x, y } = state.modifiersData[name]
        arrow.style.transform = `translate(${x || 0}px, ${y || 0}px)`
        if (x < 10 || x > state.rects.popper.width - 10 || y < 10 || y > state.rects.popper.height - 10) {
          arrow.style.visibility = 'hidden'
        } else {
          arrow.style.visibility = 'visible'
        }
      }
    },
  },
  preventOverflow: {
    name: 'preventOverflow',
    options: {
      altAxis: true,
    },
  },
}

export default POPPER_MODIFIER_CONFIG
