import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import useLatest from '@react-hook/latest'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import POPPER_MODIFIER from '../POPPER_MODIFIER_CONFIG'
import './RtDropdownMenu.css'

const MENU_WIDTH_MODIFIER = {
  same: POPPER_MODIFIER.sameWidth,
  min: POPPER_MODIFIER.minWidth,
  max: POPPER_MODIFIER.maxWidth,
}

const OFFSET_FOR_ARROW_MODIFIER = {
  name: 'offset',
  options: {
    offset: [0, 8],
  },
}

const RtDropdownMenu = React.forwardRef(
  ({ menuWidth, arrow, border, shadow, scrollable, preventOverflow, onShow, ...props }, ref) => {
    const refOnShow = useLatest(onShow)

    const propClassName = props.className || ''
    const shadowClassName = shadow === false ? '' : 'rt-shadow'
    const borderClassName =
      border === false || border === 'none' ? '' : border === 'inner' ? 'rt-inner-border' : 'rt-outer-border'
    const scrollableClassName = scrollable ? `rt-scrollable rt-menu-${scrollable === true ? 'md' : scrollable}` : ''
    const widthModifier = _.get(MENU_WIDTH_MODIFIER, menuWidth)
    const isListeningShowEvent = !!onShow

    const popperConfig = React.useMemo(() => {
      const modifiers = _.get(props.popperConfig, 'modifiers') || []
      const offsetModifier = arrow ? OFFSET_FOR_ARROW_MODIFIER : null
      const arrowModifier = arrow ? POPPER_MODIFIER.arrow : null
      const overflowModifier = preventOverflow ? POPPER_MODIFIER.preventOverflow : null
      const showEventModifier = isListeningShowEvent
        ? {
            name: 'showEventListener',
            enabled: true,
            phase: 'afterWrite',
            fn: () => {
              refOnShow.current && refOnShow.current()
            },
          }
        : null
      return {
        ...props.popperConfig,
        modifiers: _.reject(
          [overflowModifier, widthModifier, arrowModifier, offsetModifier, showEventModifier, ...modifiers],
          _.isNil,
        ),
      }
    }, [arrow, isListeningShowEvent, preventOverflow, props.popperConfig, refOnShow, widthModifier])

    return (
      <DropdownMenu
        tabIndex={-1}
        {...props}
        ref={ref}
        className={`rt-dropdown-menu ${propClassName} ${shadowClassName} ${borderClassName} ${scrollableClassName}`}
        popperConfig={popperConfig}
      >
        {props.children}
        {arrow ? <div className="rt-dropdown-menu-arrow" data-popper-arrow={true} /> : null}
      </DropdownMenu>
    )
  },
)

RtDropdownMenu.propTypes = {
  className: PropTypes.string,
  menuWidth: PropTypes.oneOf(_.keys(MENU_WIDTH_MODIFIER)),
  popperConfig: PropTypes.object,
  arrow: PropTypes.bool,
  border: PropTypes.oneOf([true, false, 'inner', 'outer', 'none']),
  shadow: PropTypes.bool,
  scrollable: PropTypes.oneOf([true, false, 'sm', 'md', 'lg', 'xl']),
  preventOverflow: PropTypes.bool,
  onShow: PropTypes.func,
  children: PropTypes.node,
}

export default RtDropdownMenu
