import React from 'react'
import { useLocation } from 'react-router-dom'

const useCurrentPath = () => {
  const location = useLocation()

  return React.useMemo(() => decodeURI(location.pathname), [location.pathname])
}

export default useCurrentPath
