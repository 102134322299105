import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'react-bootstrap/Dropdown'
import RtDropdownItem from './RtDropdownItem'
import RtDropdownItemCheck from './RtDropdownItemCheck'
import RtDropdownMenu from './RtDropdownMenu'
import RtDropdownToggle from './RtDropdownToggle'
import './RtDropdown.css'

const RtDropdown = (props) => {
  return (
    <Dropdown {...props} className={`rt-dropdown dropdown ${props.className || ''}`}>
      {props.children}
    </Dropdown>
  )
}

RtDropdown.propTypes = {
  ...Dropdown.propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
}

RtDropdown.Toggle = RtDropdownToggle
RtDropdown.Menu = RtDropdownMenu
RtDropdown.Item = RtDropdownItem
RtDropdown.ItemCheck = RtDropdownItemCheck
RtDropdown.ItemText = Dropdown.ItemText
RtDropdown.Divider = Dropdown.Divider
RtDropdown.Header = Dropdown.Header

export default RtDropdown
