import React from 'react'
import PropTypes from 'prop-types'
import MaterialSymbol from '../MaterialSymbol'
import './SuccessMessage.css'

const SuccessMessage = (props) => {
  return (
    <div className="success-layout">
      <MaterialSymbol icon={props.headingIconName} className="rt-success" size={40} />
      <h5>{props.title}</h5>
      {props.children}
    </div>
  )
}

SuccessMessage.propTypes = {
  headingIconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default SuccessMessage
