import React from 'react'
import _ from 'lodash'
import { UNSAFE_RouteContext } from 'react-router-dom'

const useEncodedParams = (decode = undefined) => {
  const { matches } = React.useContext(UNSAFE_RouteContext)
  const routeMatch = matches[matches.length - 1]
  let params = {}
  if (routeMatch) {
    params = { ...routeMatch.params }
    _.forEach(routeMatch.pathname.split('/'), (baseParam) => {
      const paramKey = _.findKey(params, (value) => value === decodeURIComponent(baseParam))
      if (paramKey) {
        params[paramKey] = decode ? decode(baseParam) : baseParam
      }
    })
  }
  return params
}

export default useEncodedParams
