import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import MaterialSymbol from '../MaterialSymbol'
import RtTooltip from '../RtTooltip'
import './RtButton.css'

const isFillVariant = (variant) => {
  return variant.match(/^fill-/) || variant === 'link'
}

const getVariant = (variant) => {
  return isFillVariant(variant) ? variant.replace(/^fill-/, '') : `outline-${variant}`
}

const RtButton = React.forwardRef(
  (
    {
      className,
      variant = 'primary',
      icon,
      iconFill,
      align = 'center',
      isRounded,
      isCircular,
      isFullSize,
      tooltipMessage,
      tooltipPlacement,
      tooltipDelay,
      isTooltipHidden,
      isDisabled,
      isLoading,
      children,
      ...props
    },
    ref,
  ) => {
    // props specifics to this component are separated from props passed to the Button component

    const isLoadingOrDisabled = isDisabled || isLoading
    const alignClassName = align ? `rt-justify-content-${align}` : ''
    const outlineClassName = !isFillVariant(variant) ? 'rt-outline' : ''
    const roundedClassName = isRounded ? 'rt-rounded' : ''
    const circularClassName = isCircular ? 'rt-circular' : ''
    const fullSizeClassName = isFullSize ? 'full-size' : ''
    const disabledClassName = isLoadingOrDisabled ? 'disabled' : ''

    const content = icon ? (
      <>
        {isLoading ? (
          <Spinner data-testid="spinner" className={`spinner ${props.size || ''}`} animation="border" size="sm" />
        ) : (
          <MaterialSymbol icon={icon} size={props.size === 'sm' ? 20 : 24} fill={iconFill} />
        )}
        <span className="button-text" style={{ display: children ? 'block' : 'none' }}>
          {children}
        </span>
      </>
    ) : (
      children
    )

    const button = (
      <Button
        {...props}
        ref={ref}
        className={`rt-button ${alignClassName} ${outlineClassName} ${roundedClassName} ${circularClassName} ${fullSizeClassName} ${disabledClassName} ${className}`}
        variant={getVariant(variant)}
        disabled={isLoadingOrDisabled}
      >
        {content}
      </Button>
    )

    return tooltipMessage ? (
      <RtTooltip
        className={fullSizeClassName}
        message={tooltipMessage}
        placement={tooltipPlacement}
        delay={tooltipDelay}
        isDisabled={!!isTooltipHidden}
      >
        <div className="rt-button-wrapper">{button}</div>
      </RtTooltip>
    ) : (
      button
    )
  },
)

RtButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.string,
  iconFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  size: PropTypes.string,
  align: PropTypes.string,
  isRounded: PropTypes.bool,
  isCircular: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  tooltipMessage: PropTypes.node,
  tooltipPlacement: PropTypes.string,
  tooltipDelay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      show: PropTypes.number,
      hide: PropTypes.number,
    }),
  ]),
  isTooltipHidden: PropTypes.bool,
  children: PropTypes.node,
}

export default RtButton
