import React from 'react'
import APP from 'environment/APP_CONFIG'

const MANIFEST = {
  short_name: APP.name,
  name: APP.title,
  icons: [
    {
      src: `${window.location.origin}${APP.faviconPath}`,
      sizes: '64x64 32x32 24x24 16x16',
      type: 'image/x-icon',
    },
  ],
  start_url: window.location.origin,
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#ffffff',
}

const AppLoader = () => {
  React.useEffect(() => {
    // Add title
    document.title = APP.title

    // Add favicon
    const favicon = document.getElementById('favicon')
    if (favicon) {
      favicon.href = APP.faviconPath
    }

    // Add manifest
    const manifest = document.getElementById('manifest')
    if (manifest) {
      manifest.href = URL.createObjectURL(new Blob([JSON.stringify(MANIFEST)], { type: 'application/json' }))
    }

    // Add app classname to the root element
    const root = document.getElementById('root')
    if (root) {
      root.classList.add(APP.styles.root)
    }
  }, [])

  return null
}

export default AppLoader
