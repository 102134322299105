import React from 'react'
import AuthenticationLayout from '../layout/AuthenticationLayout'
import ResetConfirmPage from './ResetConfirmPage'
import ResetConfirmSuccessPage from './ResetConfirmSuccessPage'

const PAGE = {
  RESET_CONFIRM: 'reset-confirm',
  CONFIRM_SUCCESS: 'confirm-success',
}

const ResetConfirmController = () => {
  const [displayedPage, setDisplayedPage] = React.useState(PAGE.RESET_CONFIRM)

  const handleResetSuccess = () => {
    setDisplayedPage(PAGE.CONFIRM_SUCCESS)
  }

  const getPage = () => {
    let result
    switch (displayedPage) {
      default:
      case PAGE.RESET_CONFIRM:
        result = <ResetConfirmPage onSuccess={handleResetSuccess} />
        break
      case PAGE.CONFIRM_SUCCESS:
        result = <ResetConfirmSuccessPage />
        break
    }
    return result
  }

  return <AuthenticationLayout>{getPage()}</AuthenticationLayout>
}

export default ResetConfirmController
