import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useTranslation } from 'react-i18next'
import If from '../If'
import MaterialSymbol from '../MaterialSymbol'
import useControlledState from '../useControlledState'
import './RtInputPassword.css'

const RtInputPassword = ({
  className,
  isDisabled,
  isPasswordShown: controlledIsPasswordShown,
  errorMessage,
  preventAutoComplete,
  onChange,
  onIsPasswordShownChange,
  ...props
}) => {
  const { t } = useTranslation(['generic'], { keyPrefix: 'RtInputPassword' })
  const [isPasswordShown, setIsPasswordShown] = useControlledState(
    controlledIsPasswordShown,
    onIsPasswordShownChange,
    false,
  )

  return (
    <InputGroup className="rt-input-password" hasValidation>
      <Form.Control
        {...props}
        type={isPasswordShown || preventAutoComplete ? 'text' : 'password'}
        className={`${className || ''} ${!isPasswordShown && preventAutoComplete ? 'input-password-as-text' : ''}`}
        spellCheck={false}
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value, e)}
      />
      <If cond={!!onIsPasswordShownChange}>
        <InputGroup.Text className="show-password-button-container">
          <Button
            className="show-password-button"
            variant="outline-secondary"
            title={t(isPasswordShown ? 'hidePasswordHelp' : 'showPasswordHelp')}
            disabled={isDisabled}
            onClick={() => setIsPasswordShown(!isPasswordShown)}
          >
            <MaterialSymbol icon={isPasswordShown ? 'visibility' : 'visibility_off'} fill={true} />
          </Button>
        </InputGroup.Text>
      </If>
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </InputGroup>
  )
}

RtInputPassword.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isPasswordShown: PropTypes.bool,
  errorMessage: PropTypes.string,
  preventAutoComplete: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onIsPasswordShownChange: PropTypes.func,
}

export default RtInputPassword
