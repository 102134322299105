import React from 'react'
import PropTypes from 'prop-types'
import AuthenticationLayout from '../layout/AuthenticationLayout'
import ResetPasswordPage from './ResetPasswordPage'
import ResetSuccessPage from './ResetSuccessPage'

const PAGE = {
  RESET_PASSWORD: 'reset-password',
  RESET_SUCCESS: 'reset-success',
}

const ResetPasswordController = (props) => {
  const [displayedPage, setDisplayedPage] = React.useState(PAGE.RESET_PASSWORD)

  const handleResetPasswordSuccess = (email) => {
    props.onEmailChange(email)
    setDisplayedPage(PAGE.RESET_SUCCESS)
  }

  const getPage = () => {
    let result
    switch (displayedPage) {
      default:
      case PAGE.RESET_PASSWORD:
        result = <ResetPasswordPage presetEmail={props.email} onResetSuccess={handleResetPasswordSuccess} />
        break
      case PAGE.RESET_SUCCESS:
        result = <ResetSuccessPage email={props.email} />
        break
    }
    return result
  }

  return <AuthenticationLayout>{getPage()}</AuthenticationLayout>
}

ResetPasswordController.propTypes = {
  email: PropTypes.string,
  onEmailChange: PropTypes.func.isRequired,
}

export default ResetPasswordController
