import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import RtCheck from '../RtCheck'
import RtDropdownItem from './RtDropdownItem'
import './RtDropdownItemCheck.css'

const RtDropdownItemCheck = React.forwardRef(({ value, onChange, ...props }, ref) => {
  const isDisabled = props.isDisabled || props.disabled

  const handleClick = (e) => {
    e.stopPropagation()
    onChange && onChange(!value, e)
    props.onClick && props.onClick(e)
  }

  return (
    <RtDropdownItem
      as="button"
      {...props}
      ref={ref}
      className={`rt-dropdown-item-check ${props.className || ''}`}
      onClick={handleClick}
    >
      <RtCheck className="rt-dropdown-item-check-form" value={value} isDisabled={isDisabled} onChange={_.noop}>
        {props.children}
      </RtCheck>
    </RtDropdownItem>
  )
})

RtDropdownItemCheck.propTypes = {
  ...RtDropdownItem.propTypes,
  value: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default RtDropdownItemCheck
