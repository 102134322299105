import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './MaterialSymbol.css'

const getFontVariationSettings = (fill, opsz, weight, grad) => {
  return _([
    ['FILL', fill],
    ['opsz', opsz],
    ['wght', weight],
    ['GRAD', grad],
  ])
    .reject(([, value]) => _.isNil(value))
    .map(([label, value]) => `'${label}' ${value}`)
    .join(', ')
}

const InlineWrapper = (props) => {
  return (
    <span className="inline-symbol" style={{ '--inline-icon-size': props.iconSize }}>
      {props.children}
    </span>
  )
}

InlineWrapper.propTypes = {
  iconSize: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const MaterialSymbol = React.forwardRef(({ icon, fill, size: sizeProp, weight, grad, inline, ...props }, ref) => {
  const size = sizeProp ? Number(sizeProp) : inline ? '1.2em' : 24
  const fontSize = isNaN(size) ? `${size}` : `${size}px`
  const fillValue = fill === true ? 1 : fill === false ? 0 : fill ? _.clamp(fill, 0, 1) : null
  const opszValue = size > 0 ? _.clamp(size, 20, 48) : null
  const weightValue = weight ? _.clamp(weight, 0, 700) : null
  const gradValue = grad ? _.clamp(grad, -25, 200) : null

  const fontVariationSettings = getFontVariationSettings(fillValue, opszValue, weightValue, gradValue)

  const symbol = (
    <i
      {...props}
      ref={ref}
      className={`material-symbols-outlined ${props.className || ''}`}
      style={{ fontSize, fontVariationSettings, ...props.style }}
    >
      {icon}
    </i>
  )

  return inline ? <InlineWrapper iconSize={fontSize}>{symbol}</InlineWrapper> : symbol
})

MaterialSymbol.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight: PropTypes.number,
  grad: PropTypes.number,
  inline: PropTypes.bool,
  style: PropTypes.object,
}

export default MaterialSymbol
