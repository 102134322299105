import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SuccessMessage from 'generic/RtSuccessMessage'
import useAppTitle from 'environment/useAppTitle'

const ResetConfirmSuccessPage = () => {
  const { t } = useTranslation(['authentication'], { keyPrefix: 'ResetConfirmSuccessPage' })
  useAppTitle(t('documentTitle'))

  return (
    <div className="reset-confirm-success-page">
      <SuccessMessage headingIconName="check_circle" title={t('successMessage')}>
        <div className="link-container">
          <Link to="/signin" className="link" replace={true}>
            {t('backToLoginLabel')}
          </Link>
        </div>
      </SuccessMessage>
    </div>
  )
}

export default ResetConfirmSuccessPage
