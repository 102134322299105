import React from 'react'

const useDocumentTitle = (title) => {
  const refPreviousTitle = React.useRef(null)
  const refCurrentTitle = React.useRef(null)

  React.useEffect(() => {
    // save document title when mounting
    refPreviousTitle.current = document.title
    return () => {
      // reset document title to the previous value when unmounting
      if (document.title === refCurrentTitle.current) {
        document.title = refPreviousTitle.current
      }
    }
  }, [])

  React.useEffect(() => {
    if (title) {
      document.title = title
      refCurrentTitle.current = title
    }
  }, [title])

  return null
}

export default useDocumentTitle
