import React from 'react'
import APP from 'environment/APP_CONFIG'
import './RawLoadingBanner.css'

const RawLoadingBanner = () => (
  <div className="raw-loading-banner">
    <div className="pulsation" />
    <div className={`app-logo ${APP.styles.backgroundIcon}`} />
  </div>
)

export default RawLoadingBanner
