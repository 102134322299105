import React from 'react'
import PropTypes from 'prop-types'
import RtLanguageDropdown from 'generic/RtLanguageDropdown'
import APP from 'environment/APP_CONFIG'
import RoseauLogo from 'assets/Roseau_Technologies_Without_Baseline.min.svg'
import './AuthenticationLayout.css'

const AuthenticationLayout = (props) => {
  return (
    <div className="authentication-layout">
      <div className="authentication-header">
        <RtLanguageDropdown />
      </div>

      <div className="authentication-body">
        <div className="authentication-container container">
          <div className="authentication-logo">
            <a href={APP.websiteUrl}>
              <APP.SvgLogo className="app-image" />
            </a>
          </div>
          {props.children}
        </div>
      </div>

      <div className="authentication-footer">
        <a href="https://www.roseautechnologies.com">
          <img className="brand-image" src={RoseauLogo} alt="Roseau Technologies" />
        </a>
      </div>
    </div>
  )
}

AuthenticationLayout.propTypes = {
  children: PropTypes.node,
}

export default AuthenticationLayout
