import _ from 'lodash'

class LocalisationHelper {
  static getLanguageCode(lng) {
    if (_.isNil(lng)) {
      return 'fr'
    } else if (lng.startsWith('en')) {
      return 'en'
    } else {
      return 'fr'
    }
  }
}

export default LocalisationHelper
