import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, resolvePath } from 'react-router-dom'
import useBasePath from './useBasePath'

const NavigateFromBase = ({ from, ...props }) => {
  const path = useBasePath(from)
  const to = React.useMemo(() => resolvePath(props.to, path), [path, props.to])

  return <Navigate {...props} to={to} />
}

NavigateFromBase.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]).isRequired,
  from: PropTypes.string,
  replace: PropTypes.bool,
}

export default NavigateFromBase
