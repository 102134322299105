import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import RtInputPassword from 'generic/RtInputPassword'
import EnvironmentContext from 'environment/EnvironmentContext'
import useAppTitle from 'environment/useAppTitle'
import AuthenticationHelper from '../AuthenticationHelper'
import FormSubmitWithRecaptcha from '../FormSubmitWithRecaptcha'
import AuthenticationLayout from '../layout/AuthenticationLayout'
import './LoginPage.css'

const ERROR_LABEL = {
  EMAIL: {
    blank: 'errorEmailBlank',
    invalid: 'errorEmailInvalid',
  },
  PASSWORD: {
    blank: 'errorPasswordBlank',
  },
  GLOBAL: {
    invalid: 'errorGlobalInvalid',
  },
}

const getErrorMessagesFromApiResponse = (loginResponse) => {
  const standard = AuthenticationHelper.getStandardErrorMessagesFromApiResponse(loginResponse)
  const email = _.get(loginResponse, 'email.0')
  const password = _.get(loginResponse, 'password.0')
  const global = _.get(loginResponse, 'nonFieldErrors.0')
  return {
    email: _.get(ERROR_LABEL.EMAIL, email, null),
    password: _.get(ERROR_LABEL.PASSWORD, password, null),
    recaptcha: standard.recaptcha,
    global: _.get(ERROR_LABEL.GLOBAL, global, null) || standard.global,
  }
}

const LoginPage = (props) => {
  const { t } = useTranslation('authentication', { keyPrefix: 'LoginPage' })
  const environment = React.useContext(EnvironmentContext)
  const [password, setPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [emailError, setEmailError] = React.useState(null)
  const [passwordError, setPasswordError] = React.useState(null)
  const [recaptchaError, setRecaptchaError] = React.useState(null)

  useAppTitle(t('documentTitle'))

  const baseUrl = environment.API_BASE_URL

  const handleFormSubmit = (recaptcha) => {
    setIsSubmitting(true)
    return AuthenticationHelper.getTokenFromCredentials(baseUrl, props.email, password, recaptcha)
      .then((response) => {
        setIsSubmitting(false)
        props.onSuccess(response)
      })
      .catch((response) => {
        const errorMessages = getErrorMessagesFromApiResponse(response)
        setIsSubmitting(false)
        setEmailError(errorMessages.email)
        setPasswordError(errorMessages.password || errorMessages.global)
        setRecaptchaError(errorMessages.recaptcha)
      })
  }

  return (
    <AuthenticationLayout>
      <div className="login-page">
        <Form.Group className="form-group">
          <Form.Label>{t('emailLabel')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('emailPlaceHolder')}
            value={props.email}
            autoFocus
            autoComplete="email"
            maxLength="150"
            isInvalid={!!emailError}
            onChange={(e) => props.onEmailChange(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">{emailError ? t(emailError) : ''}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label className="password-label">
            <span>{t('passwordLabel')}</span>
            <Link to="/reset-password" className="link">
              {t('forgottenPasswordButton')}
            </Link>
          </Form.Label>
          <RtInputPassword
            placeholder={t('passwordPlaceHolder')}
            autoComplete="current-password"
            isInvalid={!!passwordError}
            errorMessage={passwordError ? t(passwordError) : ''}
            onChange={setPassword}
          />
        </Form.Group>

        <FormSubmitWithRecaptcha
          submitLabel={t('submitLabel')}
          isSubmitting={isSubmitting}
          recaptchaError={recaptchaError}
          onSubmit={handleFormSubmit}
        />
      </div>
    </AuthenticationLayout>
  )
}

LoginPage.propTypes = {
  email: PropTypes.string,
  onEmailChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default LoginPage
