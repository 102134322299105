import _ from 'lodash'

const getAllParamsFromQueryString = () => {
  const queryParams = new window.URLSearchParams(window.location.search)
  const keyList = Array.from(queryParams.keys())
  const pairs = _.map(keyList, (key) => {
    const values = queryParams.getAll(key)
    const value = _.size(values) > 1 ? values : _.first(values)
    return [key, value]
  })
  return _.fromPairs(pairs)
}

export default getAllParamsFromQueryString
