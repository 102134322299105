import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ExactRoute from 'generic/router/ExactRoute'
import NavigateFromBase from 'generic/router/NavigateFromBase'
import useStateWithStorage from 'generic/useStateWithStorage'
import AuthenticationContext from './AuthenticationContext'
import AuthenticationHelper from './AuthenticationHelper'
import LoginPage from './login/LoginPage'
import ResetPasswordController from './login/ResetPasswordController'
import ResetConfirmController from './reset/ResetConfirmController'

const NavigateToSignin = (props) => {
  const location = useLocation()

  const { onLocationChange } = props
  React.useEffect(() => {
    onLocationChange(location)
  }, [location, onLocationChange])

  return <NavigateFromBase to="signin" replace={true} />
}

NavigateToSignin.propTypes = {
  onLocationChange: PropTypes.func.isRequired,
}

const AuthenticationProvider = (props) => {
  const [email, setEmail] = React.useState('')
  const [token, setToken] = useStateWithStorage('local', 'authToken', { oldStorageKey: 'sirao-auth-token' })
  const [desiredLocation, setDesiredLocation] = React.useState('/')
  const isAuthenticated = !_.isNil(token)

  const resetToken = React.useCallback(() => setToken(null), [setToken])

  const logout = React.useCallback(() => {
    AuthenticationHelper.logout()
    resetToken()
  }, [resetToken])

  const context = React.useMemo(
    () => ({
      token,
      resetToken,
      logout,
    }),
    [logout, resetToken, token],
  )

  return (
    <Routes>
      <Route
        path="signin/*"
        element={
          !isAuthenticated ? (
            <ExactRoute>
              <LoginPage email={email} onEmailChange={setEmail} onSuccess={setToken} />
            </ExactRoute>
          ) : (
            <Navigate to={desiredLocation} replace={true} />
          )
        }
      />
      <Route
        path="reset-password/*"
        element={
          !isAuthenticated ? (
            <ExactRoute>
              <ResetPasswordController email={email} onEmailChange={setEmail} />
            </ExactRoute>
          ) : (
            <Navigate to={desiredLocation} replace={true} />
          )
        }
      />
      <Route exact path="reset-confirm" element={<ResetConfirmController />} />
      <Route
        path="*"
        element={
          isAuthenticated ? (
            <AuthenticationContext.Provider value={context}>{props.children}</AuthenticationContext.Provider>
          ) : (
            <NavigateToSignin onLocationChange={setDesiredLocation} />
          )
        }
      />
    </Routes>
  )
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node,
}

export default AuthenticationProvider
