import _ from 'lodash'

const replaceParameters = (uriTemplate, parameters, isNilReplacedByEmptyString = false) => {
  return _.reduce(
    parameters,
    (uri, value, key) => {
      return uri.replace(`{${key}}`, _.isNil(value) && isNilReplacedByEmptyString ? '' : value)
    },
    uriTemplate,
  )
}

class WebServiceHelper {
  static getParameterizedUri(uriTemplate, parameters) {
    return _.split(uriTemplate, '?', 2)
      .map((uriPart, index) => {
        return replaceParameters(uriPart, parameters, index > 0)
      })
      .join('?')
  }

  static joinUrl(baseUrl, uri) {
    const cleanBaseUrl = _.replace(baseUrl, /\/$/, '')
    const cleanUri = _.replace(uri, /^\//, '')
    return `${cleanBaseUrl}/${cleanUri}`
  }
}

export default WebServiceHelper
