import React from 'react'
import { resolvePath } from 'react-router-dom'
import useCurrentPath from './useCurrentPath'
import useEncodedParams from './useEncodedParams'

const getBasePath = (path, params) => {
  const { '*': splat } = params
  return path.replace(splat, '').replace(/(?<!^)\/$/, '')
}

const useBasePath = (from = '') => {
  const currentPath = useCurrentPath()
  const params = useEncodedParams(decodeURI)

  const path = React.useMemo(() => resolvePath(from, getBasePath(currentPath, params)), [currentPath, from, params])

  return path.pathname
}

export default useBasePath
