import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import GoogleRecaptcha from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import LocalisationHelper from 'generic/LocalisationHelper'
import RtButton from 'generic/RtButton'
import EnvironmentContext from 'environment/EnvironmentContext'

const FormSubmitWithRecaptcha = (props) => {
  const { t, i18n } = useTranslation('authentication', { keyPrefix: 'FormSubmitWithRecaptcha' })
  const environment = React.useContext(EnvironmentContext)
  const refRecaptcha = React.useRef()
  const [recaptcha, setRecaptcha] = React.useState(null)
  const [hasNoRecaptchaError, setHasNoRecaptchaError] = React.useState(false)
  const [language, setLanguage] = React.useState(LocalisationHelper.getLanguageCode(i18n.language))

  const recaptchaError = hasNoRecaptchaError ? 'errorRecaptchaNotSet' : props.recaptchaError

  const handleSubmitClick = () => {
    if (recaptcha) {
      props.onSubmit(recaptcha)
    } else {
      setHasNoRecaptchaError(true)
    }
  }

  // on 1st render
  React.useEffect(() => {
    const recaptchaElement = refRecaptcha.current
    return () => {
      // on unmount
      recaptchaElement.reset()
    }
  }, [])

  // when changing language
  React.useEffect(() => {
    setLanguage(LocalisationHelper.getLanguageCode(i18n.language))
  }, [i18n, t])

  // when recaptcha changes
  React.useEffect(() => {
    if (recaptcha) {
      setHasNoRecaptchaError(false)
    }
  }, [recaptcha])

  // when isSubmitting change
  React.useEffect(() => {
    if (!props.isSubmitting) {
      // resets recaptcha
      refRecaptcha.current.reset()
      setRecaptcha(null)
    }
  }, [props.isSubmitting])

  return (
    <>
      <Form.Group className="form-group">
        <GoogleRecaptcha
          key={language}
          ref={refRecaptcha}
          className={`recaptcha ${recaptchaError || props.globalErrorMessage ? 'is-invalid' : ''}`}
          sitekey={environment.GOOGLE_RECAPTCHA_KEY}
          onChange={setRecaptcha}
          hl={language}
        />
        <Form.Control.Feedback type="invalid">
          {recaptchaError ? t(recaptchaError) : props.globalErrorMessage}
        </Form.Control.Feedback>
      </Form.Group>

      <RtButton
        className="recaptcha-submit"
        variant="fill-primary"
        isRounded={true}
        isDisabled={props.isSubmitting}
        onClick={handleSubmitClick}
      >
        {props.isSubmitting ? <Spinner animation="border" /> : props.submitLabel}
      </RtButton>
    </>
  )
}

FormSubmitWithRecaptcha.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  recaptchaError: PropTypes.string,
  globalErrorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default FormSubmitWithRecaptcha
