//import 'bootstrap/dist/js/bootstrap.min.js'
import React, { Suspense, lazy } from 'react'
import 'jquery/dist/jquery.min.js'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'generic/colors.css'
import AppLoader from 'environment/AppLoader'
import ENVIRONMENT from 'environment/ENVIRONMENT_CONFIG'
import RawLoadingBanner from 'pre/RawLoadingBanner'
import AuthenticationProvider from 'authentication/AuthenticationProvider'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import './index.css'

const App = lazy(() => import('./App'))
const MaintenanceRoutes = lazy(() => import('maintenance/MaintenanceRoutes'))

// https://github.com/bvaughn/react-virtualized/issues/1572
const IS_REACT_VIRTUALIZED_ISSUE_1572_FIXED = false
const Wrapper = IS_REACT_VIRTUALIZED_ISSUE_1572_FIXED ? React.StrictMode : React.Fragment
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Wrapper>
    <AppLoader />
    <Suspense fallback={<RawLoadingBanner />}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <BrowserRouter>
            {ENVIRONMENT.MAINTENANCE !== 'true' ? (
              <AuthenticationProvider>
                <App />
              </AuthenticationProvider>
            ) : (
              <MaintenanceRoutes />
            )}
          </BrowserRouter>
        </HelmetProvider>
      </I18nextProvider>
    </Suspense>
  </Wrapper>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
