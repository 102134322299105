import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormCheck from 'react-bootstrap/FormCheck'
import RtTooltip from '../RtTooltip'
import './RtCheck.css'

const RtCheck = React.forwardRef(
  (
    { value, tooltipMessage, tooltipPlacement, isDisabled, isIndeterminate, isTriState, onChange, children, ...props },
    ref,
  ) => {
    const id = React.useMemo(() => _.uniqueId('rt-checkbox-'), [])
    const refCheck = React.useRef()

    React.useImperativeHandle(ref, () => refCheck.current)

    const actualIsIndeterminate = isIndeterminate || (isTriState && _.isNil(value))
    const nextValue = !isTriState ? !value : value === false ? undefined : !value

    React.useEffect(() => {
      if (refCheck.current) {
        refCheck.current.indeterminate = actualIsIndeterminate
      }
    }, [actualIsIndeterminate])

    const formCheck = (
      <FormCheck
        id={id}
        {...props}
        ref={refCheck}
        className={`rt-check ${props.className || ''}`}
        checked={!!value}
        label={children}
        disabled={isDisabled}
        onChange={(e) => onChange && onChange(nextValue, e)}
      />
    )

    return tooltipMessage ? (
      <RtTooltip message={tooltipMessage} placement={tooltipPlacement}>
        <div>{formCheck}</div>
      </RtTooltip>
    ) : (
      formCheck
    )
  },
)

RtCheck.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
  tooltipMessage: PropTypes.node,
  tooltipPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  isTriState: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default RtCheck
