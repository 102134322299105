import React from 'react'
import PropTypes from 'prop-types'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import './RtDropdownToggle.css'

const RtDropdownToggle = React.forwardRef(({ arrow, ...props }, ref) => {
  const arrowClassName = arrow === false || arrow === 'none' ? 'no-arrow' : ''
  return (
    <DropdownToggle {...props} ref={ref} className={`rt-dropdown-toggle ${props.className || ''} ${arrowClassName}`}>
      {props.children}
    </DropdownToggle>
  )
})

RtDropdownToggle.propTypes = {
  className: PropTypes.string,
  arrow: PropTypes.oneOfType([PropTypes.bool]),
  children: PropTypes.node,
}

export default RtDropdownToggle
