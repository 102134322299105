import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import APP from 'environment/APP_CONFIG'
import ENVIRONMENT from 'environment/ENVIRONMENT_CONFIG'

const DEBUG = process.env.NODE_ENV === 'development'

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Namespaces
    ns: [
      'authentication',
      'generic',
      'header',
      'structure',
      'home',
      'tool',
      'linking',
      'analysis',
      'map',
      'maintenance',
    ],

    // Languages
    fallbackLng: {
      // 'fr-FR': ['fr'],
      // 'en-US': ['en'],
      default: ['fr'],
    },
    supportedLngs: ['fr', 'en'],
    // preload: ['fr'],

    // Development configuration
    debug: false,
    reloadOnPrerender: DEBUG,

    // interpolation
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      defaultVariables: { appName: APP.name, appSupportEmail: APP.supportEmail },
    },
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${ENVIRONMENT.VERSION}`,
    },
  })

export default i18n
